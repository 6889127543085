import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        py: 2, // Reduced padding for a more compact look
        mt: "auto", // Pushes footer to the bottom when content is short
        position: "relative", // Handles scrolling
        background: (theme) => theme.palette.background.footer,        
            }}
          >
            <Container maxWidth="lg">
        <Grid container spacing={2}> {/* Reduced spacing for compactness */}
          {/* Left Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
              <img
                src="https://hireveda.com/content/images/2024/02/HV-Logo-White.png"
                alt="HireVeda Logo"
                height="15px"
              />
            </Typography>
            <Typography variant="body2" gutterBottom>
              © {new Date().getFullYear()} HireVeda. All rights reserved.
            </Typography>
          </Grid>

          {/* Center Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}> {/* Reduced gap */}
              <Link
                href="https://hireveda.com/about/"
                color="inherit"
                underline="hover"
              >
                About Us
              </Link>
              <Link
                href="https://hireveda.com/recruitement/"
                color="inherit"
                underline="hover"
              >
                Recruitment Services
              </Link>
              <Link
                href="https://www.linkedin.com/company/hireveda/"
                color="inherit"
                underline="hover"
              >
                LinkedIn Page
              </Link>
            </Box>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}> {/* Reduced gap */}
              <Link
                href="https://hireveda.com/Privacy-Policy/t"
                target="_blank"
                color="inherit"
                underline="hover"
              >
                Privacy Policy
              </Link>
              <Link
                href="https://hireveda.com/tnc/"
                target="_blank"
                color="inherit"
                underline="hover"
              >
                Terms of Service
              </Link>
              <Link
                href="https://hireveda.com/refund-terms/"
                target="_blank"
                color="inherit"
                underline="hover"
              >
                Refunds & Returns Policy
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
