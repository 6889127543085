import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
      setUser({ role: 'guest' });
    }
     // Configure Axios interceptor when user state changes
    const interceptor = axios.interceptors.request.use(
      (config) => {
        if (user) {
          config.headers.Authorization = `Bearer ${user.token}`;
          config.headers.Email = user.email;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      // Remove the interceptor when the component unmounts or user changes
      axios.interceptors.request.eject(interceptor);
    };

  }, [user]);

  const updateProfile = async (values) => {
    if (!user || !user.id) {
      console.error('No user id available for update'+ JSON.stringify(values));
  //    alert('You must be logged in to update your profile.');
      return;
    }

    try {
      const updates = { ...values };
      const response = await axios.post('/api/users/update', {
        id: user.id,
        updates
      });

      setUser(response.data);
      
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert('Update failed: ' + error.message);
    }
  };


  const isAdmin = user && user.role === 'admin';
  const isCandidate = user && user.role === 'candidate';
  const isClient = user && user.role === 'client';


  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.data && response.data.user) {
        setUser(response.data.user);
        return response.data.user;
      } else {
        throw new Error('User data is incomplete.');
      }
    } catch (error) {
      console.error('Login failed:', error.response?.data?.message || error.message);
      throw error;
    }
  };


  const oauthLogin = async (idToken, accessToken) => {
    try {     
      const response = await axios.post('/api/oauth-login', { token: idToken, accessToken });
      if (response.data && response.data.user) {
        setUser(response.data.user);
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Store the user
        return response.data.user;
      } else {
        throw new Error('OAuth login failed');
      }
    } catch (error) {
      console.error('OAuth login failed:', error.response?.data?.message || error.message);
      throw error;
    }
  };
  
  const signup = async (values) => {
    try {
      const response = await axios.post('/api/signup', values);
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('Signup failed:', error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || 'Signup failed');
    }
  };

  const logout = async () => {
    try {
      await axios.post('/api/logout');
      setUser(null);
      setUser({ role: 'guest' });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };


  return (
    <AuthContext.Provider value={{ user, login, logout, oauthLogin, signup, updateProfile, isAdmin, isCandidate, isClient }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
