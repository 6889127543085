// componentsMap.js
import AdminDashboard from '../AdminDashboard';
import CandidateDashboard from '../CandidateDashboard';
import ClientDashboard from '../Candidates/ClientDashboard';
import EditCandidates from '../EditCandidate';
import AdminUserManagement from '../AdminUserManagement';
import Signup from '../Signup';
import Login from '../Login';
import RecoverPassword from '../RecoverPassword';
import EditProfile from '../EditProfile';
import AdminMergeComponent from '../AdminMergeComponent';
import BulkUpdateComponent from '../BulkUpdateComponent';
import OAuthCallback from '../OAuthCallback';
import FileUploadComponent from '../FileUploadComponent';
import RoleManagement from '../Admin/RoleManagement';
import AdminStatusManager from '../Admin/AdminStatusManager';
import Associations from '../Candidates/Association';
import linkedintab from '../Admin/linkedintab';
import apply from '../CandidateForm';
import JobPage from '../Jobs/JobPage';
import ApplyPage from '../Jobs/ApplyPage.js';
import EmailCampaignDashboard from '../Emails/EmailCampaignDashboard';
import EmailReaderDashboard from '../Admin/EmailReaderDashboard';
import ProfilePage from '../extention/profilepage.js';
import Execedge from '../ExecEdge/dashboard.js';
import GoogleCallback from '../GoogleCallback';

const componentsMap = {
  AdminDashboard,
  GoogleCallback,
  CandidateDashboard,
  ClientDashboard,
  EditCandidates,
  AdminUserManagement,
  Signup,
  Login,
  RecoverPassword,
  EditProfile,
  AdminMergeComponent,
  BulkUpdateComponent,
  OAuthCallback,
  FileUploadComponent,
  RoleManagement,
  AdminStatusManager,
  Associations,
  linkedintab,
  apply,
  JobPage,
  ApplyPage,
  EmailCampaignDashboard,
  EmailReaderDashboard,
  ProfilePage,
  Execedge,
};

export default componentsMap;
