import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const steps = [
  "Personal Details",
  "Current Role & Responsibilities",
  "Career Goals",
  "Preferences & Challenges",
  "Achievements & Skills",
  "Additional Information",
];

const instructions = {
  fullName: "Enter your full legal name as it appears on official documents.",
  education: "List your educational background, including degrees and certifications.",
  currentCompany: "Enter the name of your current employer.",
  currentPosition: "Enter your current job title or position.",
  topResponsibilities:
    "Think about the main tasks or responsibilities you manage daily or weekly in your current role. Focus on the areas where you contribute the most value to your team or organization.",
  topPeopleWorkedWith:
    "List out names of established professionals who have been your colleagues, bosses, or mentors.",
  wordCloud: "Think about 10–15 words that define you as a professional.",
  yearsExperience:
    "Exclude internships and part-time jobs. Only include full-time professional experience.",
  desiredRoles: "Describe the job title or role you are targeting in your next career move.",
  desiredIndustries: "List the industries you are most interested in working within.",
  notDesiredIndustries:
    "Consider industries that do not align with your career goals, interests, or values. List up to three industries to avoid.",
  preferredCompanySize:
    "Specify the size of companies you prefer to work for (e.g., number of employees, funding raised, annual revenue).",
  preferredCompanyType:
    "Think about the type of work environment where you thrive the most. (May select multiple)",
  targetCompanies:
    "Think about companies where you aspire to work. List 7–10 companies that match your career aspirations.",
  preferredReportingTo: "List 3–5 titles of roles you would prefer to report to.",
  locationPreference: "Provide cities or regions where you are looking to work.",
  importantFactors:
    "Think about key factors for choosing your next role, such as compensation, work-life balance, company culture, etc.",
  jobSearchChallenges:
    "Think about the main obstacles you have faced while searching for a new role.",
  currentCTC: "Enter your current CTC details.",
  expectedCTC: "Enter your expected CTC for the next role.",
  achievements:
    "Share major accomplishments or milestones you have achieved in your career.",
  usps: "Describe what sets you apart from other candidates, including unique skills or experiences.",
  reasonToLeave: "Share your reasons for leaving your current position.",
  workEnvironment:
    "Describe the kind of workplace culture and environment where you perform best.",
  longTermGoals: "Describe your long-term career aspirations.",
  references: "Provide names and contact details of professional references.",
  additionalInfo: "Add any relevant information for your job search.",
};

const SelfAssessment = ({ email, SelfAssessment }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [fieldStatus, setFieldStatus] = useState({});
  const [focusedField, setFocusedField] = useState(null);
  const [loadingField, setLoadingField] = useState(null);

  useEffect(() => {
    if (SelfAssessment) {
      const initialData = {};
      SelfAssessment.forEach((item) => {
        initialData[item.Question] = item.Answer;
      });
      setFormData(initialData);
    }
  }, [SelfAssessment]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Auto-resize textarea
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;

    handleSave(name, value);
  };

  const handleSave = async (name, value) => {
    setLoadingField(name);
    try {
     
      const response = await axios.post("/api/execedge/addSelfAssessmentByEmail", {
        email,
        Question: name,
        Answer: value,
      });
      if (response.status === 200) {
        setFieldStatus((prev) => ({ ...prev, [name]: "success" }));
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setFieldStatus((prev) => ({ ...prev, [name]: "error" }));
    } finally {
      setLoadingField(null);
    }
  };

  const handleStepClick = (index) => setActiveStep(index);
  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const renderInput = (fieldName, label, isMultiline = false) => (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <TextField
        fullWidth
        name={fieldName}
        label={label}
        value={formData[fieldName] || ""}
        onChange={handleChange}        
        onFocus={() => setFocusedField(fieldName)}
        onBlur={() => setFocusedField(null)}
        multiline={isMultiline}
        rows={isMultiline ? 1 : 1}
        margin="normal"
        sx={{
          border: fieldStatus[fieldName] === "success" ? "2px solid green" : "none",
        }}
      />
      {loadingField === fieldName && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
          }}
        />
      )}
      {focusedField === fieldName && (
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            top: "-20px",
            left: "0",
            backgroundColor: "#f5f5f5",
            padding: "5px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          {instructions[fieldName]}
        </Typography>
      )}
    </Box>
  );

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            {renderInput("fullName", "Full Name")}
            {renderInput("education", "Education", true)}
          </>
        );
      case 1:
        return (
          <>
            {renderInput("currentCompany", "Current Company")}
            {renderInput("currentPosition", "Current Position")}
            {renderInput("topResponsibilities", "Top 3 Responsibilities (Current)", true)}
          </>
        );
      case 2:
        return (
          <>
            {renderInput("desiredRoles", "Top 3 Desired Roles", true)}
            {renderInput("desiredIndustries", "Desired Industries", true)}
            {renderInput("notDesiredIndustries", "Top Industries You Are NOT Interested In", true)}
          </>
        );
      case 3:
        return (
          <>
            {renderInput("importantFactors", "Most Important Factors (Up to 3)", true)}
            {renderInput("jobSearchChallenges", "Biggest Challenges in Job Search", true)}
          </>
        );
      case 4:
        return (
          <>
            {renderInput("achievements", "Notable Achievements (Top 5)", true)}
            {renderInput("usps", "Unique Selling Propositions (USPs) / Skills", true)}
          </>
        );
      case 5:
        return renderInput("additionalInfo", "Additional Information", true);
      default:
        return null;
    }
  };

  return (
    <Box sx={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Self Assessment
      </Typography>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>
              <Typography
                variant="subtitle1"
                sx={{
                  cursor: "pointer",
                  textDecoration: activeStep === index ? "underline" : "none",
                }}
                onClick={() => handleStepClick(index)}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ marginTop: "20px" }}>{renderStepContent()}</Box>

      <Box sx={{ marginTop: "20px" }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ marginRight: "10px" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={activeStep === steps.length - 1 ? () => alert("Form Submitted") : handleNext}
        >
          {activeStep === steps.length - 1 ? "Submit" : "Next"}
        </Button>
      </Box>

    </Box>
  );
};

export default SelfAssessment;
