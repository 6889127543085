import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Fab,
  MenuItem,
  Select,
  Alert,
  Typography,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useAuth } from '../AuthContext';

const Setup = ({ Project }) => {
  const { user } = useAuth();
  const { _id: ProjectId } = Project;
  const [template, setTemplate] = useState({});
  const [templates, setTemplates] = useState([]);
  const [alert, setAlert] = useState(null);
  const [subject, setsubject] = useState('');
  const placeholders = ["{{FirstName}}", "{{Company Name}}"];
  const quillRef = React.useRef(null); // Reference for ReactQuill editor

  // Fetch templates from the API
  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`/api/emailTemplates/project/${ProjectId}`);
      setTemplates(response.data);
      if (response.data.length > 0) {
        setTemplate(response.data[0]);
        setsubject(response.data[0].subject || '');
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [ProjectId]);

  // Insert placeholder into the email body
  const insertPlaceholder = (placeholder) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, placeholder);
      } else {
      }
    }
  };

  // Save templates to the API
  const saveTemplate = async () => {
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    try {
      const updatedTemplates = templates.map((temp) => ({
        ...temp,
        subject: subject, // Update subject for all templates
        body: temp._id === template._id ? template.body : temp.body, // Update body of the current template
      }));
      await Promise.all(
        updatedTemplates.map((temp) =>
          axios.put(`/api/emailTemplates/update/${temp._id}`, temp)
        )
      );
      setAlert({ severity: 'success', message: 'Templates saved successfully!' });
      setTimeout(() => setAlert(null), 5000);
      await fetchTemplates();
    } catch (error) {
      console.error('Error saving templates:', error);
      setAlert({ severity: 'error', message: 'Failed to save the templates.' });
    }
  };

  return (
    <Box sx={{ margin: '0 auto', width: '100%', padding: 3 }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Email Templates
      </Typography>
      <Tabs
        value={templates.findIndex((temp) => temp._id === template._id)}
        onChange={(event, newIndex) => setTemplate(templates[newIndex])}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
      >
        {templates.map((temp, index) => (
          <Tab key={index} label={temp.name} />
        ))}
      </Tabs>
      <Box mt={3} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          label="subject (Shared Across All Templates)"
          variant="outlined"
          size="small"
          value={subject}
          onChange={(event) => setsubject(event.target.value)}
          fullWidth
        />
        {template && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                backgroundColor: '#f9f9f9',
                padding: 1,
                borderRadius: '8px',
              }}
            >
              <Select
                displayEmpty
                size="small"
                defaultValue=""
                onChange={(event) => insertPlaceholder(event.target.value)}
                style={{ minWidth: '200px' }}
              >
                <MenuItem value="" disabled>
                  Insert Placeholder
                </MenuItem>
                {placeholders.map((placeholder) => (
                  <MenuItem key={placeholder} value={placeholder}>
                    {placeholder}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <ReactQuill
              ref={quillRef}
              value={template.body || ''}
              onChange={(value) =>
                setTemplate((prev) => ({ ...prev, body: value }))
              }
              style={{ height: '250px', border: '1px solid #ececec', borderRadius: '8px' }}
            />
          </>
        )}
        <Fab
          variant="extended"
          color="primary"
          onClick={saveTemplate}
          sx={{ alignSelf: 'flex-start' }}
        >
          Save Templates
        </Fab>
      </Box>
      {alert && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity={alert.severity} sx={{ mt: 2 }}>
          {alert.message}
        </Alert>
      )}
    </Box>
  );
};

export default Setup;
