import React, { useState } from "react";
import DatePicker from "react-datepicker";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import ScheduleIcon from "@mui/icons-material/Schedule";
import SendIcon from "@mui/icons-material/Send";

const ComposeEmail = ({ open, onClose, onSubmit }) => {
  const [emailData, setEmailData] = useState({
    from: "",
    to: "",
    subject: "",
    body: "",
    scheduledAt: null,
    isScheduled: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setEmailData((prev) => ({ ...prev, scheduledAt: date }));
  };

  const handleCheckboxChange = (e) => {
    setEmailData((prev) => ({ ...prev, isScheduled: e.target.checked }));
  };

  const handleSubmit = () => {
    onSubmit(emailData);
    onClose(); // Close the dialog after submission
    setEmailData({
      from: "",
      to: "",
      subject: "",
      body: "",
      scheduledAt: null,
      isScheduled: false,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Compose Email</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="From"
              name="from"
              value={emailData.from}
              onChange={handleChange}
              placeholder="Enter sender email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="To"
              name="to"
              value={emailData.to}
              onChange={handleChange}
              placeholder="Enter recipient email(s)"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Subject"
              name="subject"
              value={emailData.subject}
              onChange={handleChange}
              placeholder="Enter email subject"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Body"
              name="body"
              value={emailData.body}
              onChange={handleChange}
              multiline
              rows={4}
              placeholder="Enter email body"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailData.isScheduled}
                  onChange={handleCheckboxChange}
                />
              }
              label="Schedule this email"
            />
          </Grid>
          {emailData.isScheduled && (
            <Grid item xs={12}>
            

<TextField
        fullWidth
        label="Schedule At"
        value={emailData.scheduledAt ? emailData.scheduledAt.toLocaleString() : ''}
        InputProps={{
          readOnly: true,
        }}
        onClick={() => emailData.scheduledAt=null} // open the picker
      />
      <DatePicker
        selected={emailData.scheduledAt}
        onChange={handleDateChange}
        showTimeSelect
        dateFormat="Pp"
      />
 </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={emailData.isScheduled ? <ScheduleIcon /> : <SendIcon />}
          onClick={handleSubmit}
        >
          {emailData.isScheduled ? "Schedule Email" : "Send Email"}
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComposeEmail;
