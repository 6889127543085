//import '../App.css';

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  FormControl,
  Chip,
  Box,
  Slider,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Collapse,
  CircularProgress,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import debounce from 'lodash/debounce';


const Filters = ({
  selectedFilters,
  onFilterChange,
  compensationRange,
  onCompensationChange,
  experienceRange,
  onExperienceChange,
  onBooleanKeywordChange,
  onMinDurationChange,
  onNoticePeriodChange,
  resetAllFilters,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const [location, setLocation] = useState([]);
  const [categories, setCategories] = useState([]);
  const [keywords, setKeywords] = useState(selectedFilters.keywords || []);
  const [loading, setLoading] = useState(true);
  const [degrees, setDegrees] = useState([]);
  const [skills, setSkills] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [error, setError] = useState(null);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [booleanKeywords, setBooleanKeywords] = useState('');

  const fetchFilterData = useCallback(async () => {
    try {
      const [degreesRes, institutionsRes, skillsRes, locationRes, categoriesRes] = await Promise.all([
        axios.get('/api/degrees'),        
        axios.get('/api/institutions'),
        axios.get('/api/skills'),
        axios.get('/api/locations'),
        axios.get('/api/categories'),
      ]);

      setDegrees(degreesRes.data || []);
      setInstitutions(institutionsRes.data || []);
      setSkills(skillsRes.data || []);
      setLocation(locationRes.data || []);
      setCategories(categoriesRes.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching filter data:', error);
      setError('Failed to load filters');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFilterData();
  }, [fetchFilterData]);

  const fetchOrganizations = useCallback(
    debounce(async (query) => {
      setLoadingOrgs(true);
      try {
        const response = await axios.get('/api/organizations', {
          params: { query },
        });
        setOrganizations(response.data.map(org => ({ name: org.name, id: org.id, logo: org.logo })) || []);
      } catch (error) {
        console.error('Error fetching organizations:', error);
        setError('Failed to load organizations');
      } finally {
        setLoadingOrgs(false);
      }
    }, 300), // Debounce with a 300ms delay
    []
  );

  useEffect(() => {
    fetchOrganizations('');
  }, [fetchOrganizations]);

  const handleKeywordAddition = (keyword) => {
    if (keyword && !keywords.includes(keyword)) {
      const newKeywords = [...keywords, keyword];
      setKeywords(newKeywords);
      onFilterChange('keywords', newKeywords);
    }
  };

  const handleKeywordRemoval = (removedKeyword) => {
    const newKeywords = keywords.filter((keyword) => keyword !== removedKeyword);
    setKeywords(newKeywords);
    onFilterChange('keywords', newKeywords);
  };

  const renderKeywords = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {keywords.map((keyword, index) => (
        <Chip
          key={index}
          label={keyword}
          onDelete={() => handleKeywordRemoval(keyword)}
          style={{ margin: '3px' }}
        />
      ))}
    </Box>
  );

  const handleCompensationChange = (event, newValue) => {
    onCompensationChange(newValue);
    onFilterChange('compensationRange', newValue);
  };

  const handleExperienceChange = (event, newValue) => {
    onExperienceChange(newValue);
    onFilterChange('experienceRange', newValue);
  };

  const handleResetFilters = () => {
    resetAllFilters();
    setKeywords([]);
  };

  if (loading) return <p>Loading filters...</p>;
  if (error) return <p>Error loading filters: {error}</p>;

  return (
    <Box sx={{ padding: 2, margin: 2, border: '1px solid #ccc', borderRadius: 2 }}>
      <Button
        variant="contained"
        startIcon={<Refresh />}
        onClick={handleResetFilters}
        sx={{ marginBottom: 2 }}
      >
        Remove All Filters
      </Button>
      <Collapse in={filtersVisible}>
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">Keywords ({keywords.length})</Typography>
          <TextField
            placeholder="Type and add keywords"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleKeywordAddition(event.target.value);
                event.target.value = '';
                event.preventDefault();
              }
            }}
            sx={{ marginBottom: 2 }}
            />
            {renderKeywords()}
            </Box>
            <Box sx={{ marginBottom: 3 }}>
            <Typography variant="h6">Boolean Keywords</Typography>
            <FormControl fullWidth>
            <TextField            
              value={selectedFilters.booleanKeywords || ""}
              onChange={(event) => {
                onFilterChange('booleanKeywords', event.target.value)
                setBooleanKeywords(event.target.value);
              }}
              onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onFilterChange('booleanKeywords', event.target.value);
                setBooleanKeywords(event.target.value);
                event.preventDefault();
              }
                }}
                InputProps={{
                  endAdornment: (
                  booleanKeywords && (
                    <Button
                    onClick={() => {
                      onFilterChange('booleanKeywords', '');
                      setBooleanKeywords('');
                    }}
                    >
                    <Refresh />
                    </Button>
                  )
                  ),
                }}
                />
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="h6">
              Compensation Range ({compensationRange.join(' - ')}) Lacs              
              {( compensationRange != [0,200] && ( 
                <Button
                onClick={() => {
                onCompensationChange([0, 200]);
                onFilterChange('compensationRange', [0, 200]);
                }}
                >               
                <Refresh />
                </Button>
                  ) )} 
              </Typography>
              <Slider
              value={compensationRange}
              onChange={handleCompensationChange}
              valueLabelDisplay="auto"
              min={0}
              max={200}
              width={80}
              />
                </Box>
              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6">
                Experience Range ({experienceRange.join(' - ')}) years
                <Button
              onClick={() => {
                onExperienceChange([0, 30]);
                onFilterChange('experienceRange', [0, 200]);
                }}
                ><Refresh /></Button>
                </Typography>
                <Slider
                value={experienceRange}
                onChange={handleExperienceChange}
                valueLabelDisplay="auto"
                min={0}
                max={30}
                />
            </Box>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="h6">
              Location ({selectedFilters.location?.length || 0})
              {
                 selectedFilters.location && (
              <Button
              onClick={() => {
                onFilterChange('location', []);
                }}
                >
                <Refresh />
                </Button>
                )}
                </Typography>
                <Autocomplete
                multiple
                options={location.map(loc => loc.name)}
                value={selectedFilters.location || []}
                onChange={(event, newValue) => onFilterChange('location', newValue)}
                renderInput={(params) => (
                <TextField
                {...params}
                label="Select Location"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                  <>
                  {loading && <CircularProgress size={20} />}
                  {params.InputProps.endAdornment}
                  </>
                  ),
                }}
                />
                )}
                />
              </Box>
              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6">Category ({selectedFilters.category?.length || 0})
                {( selectedFilters.category && ( <Button
                onClick={() => {
                onFilterChange('category', []);
              }}
              >
              <Refresh />
              </Button>
              ))}
              </Typography>
              <Autocomplete
              multiple
              options={categories}
              getOptionLabel={(option) => option}
              value={selectedFilters.category || []}
              onChange={(event, newValue) => onFilterChange('category', newValue)}
              renderInput={(params) => (
                <TextField
                {...params}
                label="Select categories"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                  <>
                    {loading && <CircularProgress size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                  ),
                }}
                />
              )}
              />
            </Box>
       
        {/*
        
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">Minimum Duration in Companies (months)</Typography>
          <Slider
            value={selectedFilters.minDuration || 0}
            onChange={(event, newValue) => {
              onMinDurationChange(newValue);
              onFilterChange('minDuration', newValue);
            }}
            valueLabelDisplay="auto"
            min={0}
            max={60}
            marks={[
              { value: 0, label: '0' },
              { value: 15, label: '15' },
              { value: 30, label: '30' },
              { value: 45, label: '45' },
              { value: 60, label: '60' },
            ]}
          />
        </Box>  
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">Notice Period</Typography>
          <FormControl fullWidth>
            <InputLabel>Select notice period</InputLabel>
            <Select
              value={selectedFilters.noticePeriod || ''}
              onChange={(event) => {
                onNoticePeriodChange(event.target.value);
                onFilterChange('noticePeriod', event.target.value);
              }}
            >
              <MenuItem value="15">15 days</MenuItem>
              <MenuItem value="30">30 days</MenuItem>
              <MenuItem value="60">60 days</MenuItem>
              <MenuItem value="90">90 days</MenuItem>
              <MenuItem value="120">120 days</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">
            Education Degree ({selectedFilters.educationDegree?.length || 0})
          </Typography>
          <Autocomplete
            multiple
            options={degrees}
            getOptionLabel={(option) => option.name}
            value={selectedFilters.educationDegree || []}
            onChange={(event, newValue) => onFilterChange('educationDegree', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select degrees"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">
            Institution ({selectedFilters.institution?.length || 0})
          </Typography>
          <Autocomplete
            multiple
            options={institutions}
            getOptionLabel={(option) => option.name}
            value={selectedFilters.institution || []}
            onChange={(event, newValue) => onFilterChange('institution', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select institutions"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">
            Organization ({selectedFilters.organization?.length || 0})
          </Typography>
          <Autocomplete
            multiple
            options={organizations}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={option.logo} sx={{ marginRight: 1 }} />
                  {option.name}
                </Box>
              </li>
            )}
            onInputChange={(event, newInputValue) => {
              fetchOrganizations(newInputValue);
            }}
            value={selectedFilters.organization || []}
            onChange={(event, newValue) => onFilterChange('organization', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select organizations"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingOrgs && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6">Skills ({selectedFilters.skills?.length || 0})</Typography>
          <Autocomplete
            multiple
            options={skills.map((skill) => skill.name)}
            value={selectedFilters.skills || []}
            onChange={(event, newValue) => onFilterChange('skills', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select skills"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        
        */}
      </Collapse>
    </Box>
  );
};

export default Filters;
