import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, CircularProgress, Box, IconButton } from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles for the editor
import { AddCircle as AddCircleIcon, Delete as DeleteIcon } from '@mui/icons-material';  // For adding/removing variables
import { useAuth } from '../AuthContext';

const EmailTemplateManager = () => {  
    const { user } = useAuth();
  
  const [templates, setTemplates] = useState([]);  // List of all templates
  const [newTemplate, setNewTemplate] = useState({ name: '', subject: '', body: '', fields: {}, switches: [] });  // Data for new template
  const [preview, setPreview] = useState(null);  // Preview of the email template
  const [loading, setLoading] = useState(false);  // Loading state for async actions
  const [variableInput, setVariableInput] = useState('');  // Input for new variable
  const  userId = user.id;
  // Fetch templates from the backend API
  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/emailTemplates/user/'+ userId ); // Replace with actual user ID
      setTemplates(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching templates:', error);
      setLoading(false);
    }
  };

  // Create a new template
  const handleCreateTemplate = async () => {
    if (!newTemplate.name || !newTemplate.subject || !newTemplate.body) {
      alert('Please fill in all fields');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('/api/emailTemplates/create',  { ...newTemplate, userId });
      setTemplates([...templates, response.data]);
      setLoading(false);
    } catch (error) {
      console.error('Error creating template:', error);
      setLoading(false);
    }
  };

  // Preview the template
  const handlePreviewTemplate = async () => {
    if (!newTemplate.name || !newTemplate.subject || !newTemplate.body) {
      alert('Please fill in all fields to preview the template');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('/api/emailTemplates/preview', { templateId: newTemplate._id, data: newTemplate.fields });
      setPreview(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error previewing template:', error);
      setLoading(false);
    }
  };

  // Update the body of the new template using ReactQuill editor
  const handleBodyChange = (value) => {
    setNewTemplate((prevState) => ({
      ...prevState,
      body: value,
    }));
  };

  // Add a variable to the template
  const handleAddVariable = () => {
    if (variableInput && !newTemplate.fields[variableInput]) {
      setNewTemplate((prevState) => ({
        ...prevState,
        fields: { ...prevState.fields, [variableInput]: '' },
      }));
      setVariableInput('');
    }
  };

  // Remove a variable from the template
  const handleRemoveVariable = (variable) => {
    const updatedFields = { ...newTemplate.fields };
    delete updatedFields[variable];
    setNewTemplate((prevState) => ({
      ...prevState,
      fields: updatedFields,
    }));
  };

  // Fetch templates on component mount
  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div>
      <Typography variant="h4">Manage Email Templates</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Template Name"
            fullWidth
            value={newTemplate.name}
            onChange={(e) => setNewTemplate({ ...newTemplate, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Subject"
            fullWidth
            value={newTemplate.subject}
            onChange={(e) => setNewTemplate({ ...newTemplate, subject: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Body</Typography>
          <ReactQuill
            value={newTemplate.body}
            onChange={handleBodyChange}
            placeholder="Write your email content here..."
            theme="snow"
            style={{ height: '200px' }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Variables</Typography>
          <Box display="flex" alignItems="center">
            <TextField
              label="Add Variable"
              value={variableInput}
              onChange={(e) => setVariableInput(e.target.value)}
              placeholder="e.g., {{name}}"
            />
            <IconButton onClick={handleAddVariable} disabled={!variableInput}>
              <AddCircleIcon />
            </IconButton>
          </Box>
          <Box>
            {Object.keys(newTemplate.fields).map((variable) => (
              <Paper key={variable} style={{ padding: '5px', marginTop: '10px' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="body1">{`{{${variable}}}`}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleRemoveVariable(variable)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handleCreateTemplate} disabled={loading}>Create Template</Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handlePreviewTemplate} disabled={loading}>Preview Template</Button>
        </Grid>
      </Grid>

      {loading && (
        <CircularProgress />
      )}

      {preview && (
        <Paper style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h6">Preview</Typography>
          <Typography variant="h6">{preview.subject}</Typography>
          <div dangerouslySetInnerHTML={{ __html: preview.body }} />
        </Paper>
      )}

      <Typography variant="h5">Your Templates</Typography>
      {!Array.isArray(templates) || templates.length === 0 ? (
        <Typography>No templates found. Please create a new template.</Typography>
      ) : (
        templates.map((template) => (
          <Paper key={template._id} style={{ padding: '10px', marginTop: '10px' }}>
            <Typography variant="h6">{template.name}</Typography>
            <Typography variant="body1">{template.subject}</Typography>
          </Paper>
        ))
      )}
    </div>
  );
};

export default EmailTemplateManager;
