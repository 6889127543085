import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FormControl, InputLabel, MenuItem, Select, Button,Box, Avatar, Typography, Grid, IconButton, Chip, TextField} from '@mui/material';
import Autosuggest from '@mui/material/Autocomplete';
import { LinkedIn, Phone, Email } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAuth } from '../AuthContext';
import CandidateForm from '../utils/candidateForm';

function ProfilePage() {
  const { profileUrl } = useParams() || ''; // Get LinkedIn profile URL from the URL
  const [candidate, setcandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assoData, setAssoData] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(() => localStorage.getItem('selectedClient') || '');
  const [selectedRole, setSelectedRole] = useState(() => localStorage.getItem('selectedRole') || '');
  const [EE_data, setEE_data] = useState([]);
  const [EE_client, setEE_client] = useState(() => localStorage.getItem('selectedEEClient') || '');
  const [EE_Company, setEE_Company] = useState(() => localStorage.getItem('selectedEECompany') || '');
  const {user}  = useAuth();
 // const [showPriority, setShowPriority] = React.useState(false);
 // const [stages, setStages] = useState([]);
  const [changeOptionsMap, setChangeOptionsMap] = useState({});
  const [Tags, setTags] = useState([]);

 // const [open, setOpen] = useState(false);

//  const handleOpen = () => setOpen(true);
//  const handleClose = () => setOpen(false);
useEffect(() => {
  if(!user || !user.email || user.role === 'candidate') {
    window.location.href = '/';
  }
}, [user]);

  useEffect(() => {
    localStorage.setItem('selectedClient', selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    localStorage.setItem('selectedRole', selectedRole);
  }, [selectedRole]);

  useEffect(() => {
    localStorage.setItem('selectedEEClient', EE_client);
  }, [EE_client]);

  useEffect(() => {
    localStorage.setItem('selectedEECompany', EE_Company);
  }, [EE_Company]);


  useEffect(() => {
    
    console.log('test' + user);
  }, [user]);

  useEffect(() => {
    fetchClientsWithActiveRoles();
    fetchEEData();
  }, []);

  const fetchClientsWithActiveRoles = async () => {
    try {
      const response = await axios.get('/api/associations/clients-with-active-roles');      
      setClients(response.data || []);
      const response1 = await axios.get('/api/Tag/List');
      setTags(response1.data || []);      
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchEEData = async () => {
    try {
      const response = await axios.get('/api/execedge/getAll');
      setEE_data(response.data || []);
    } catch (error) {
      console.error('Error fetching EE data:', error);
    }
  };

  const addTags = async (id,tags) => {
    if (!tags || tags.trim() === '') {
      return;
    }
    try {
      await axios.post(`/api/addTags/${id}`, {
        tags: tags,
      });
      setcandidate({...candidate, Tags: [...candidate.Tags, tags]});
    } catch (error) {
      console.error('Error adding tags:', error);
      alert('Failed to add tags. Please try again.');
    }
  };

  const removeTags = async (id,tags) => {
    try {
      await axios.post(`/api/removeTags/${id}`, {
        tags: tags,
      });
      setcandidate({...candidate, Tags: candidate.Tags.filter(tag => tag !== tags)});
    } catch (error) {
      console.error('Error removing tags:', error);
      alert('Failed to remove tags. Please try again.');
    }
  };

  

  const handleClientChange = (event) => {
    const clientName = event.target.value;
    setSelectedClient(clientName);
    setSelectedRole('');
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  
  const addToEE = async () => {
    try {      
      const selectedEEClient = EE_data.find(client => client.name === EE_client);
      console.log("data",EE_data);
      console.log("Client",EE_Company);
      const selectedEECompany = selectedEEClient?.TargetCompany.find(company => company.name === EE_Company);
      console.log("company",selectedEECompany);
      await axios.post('/api/execedge/associations', {
         candidateId: candidate._id,
         TargetCompanyId: selectedEECompany._id,
         ExecEdgeId: selectedEEClient._id,
         status: "Added",
      });
      setAssoData([...assoData, { RoleName: EE_client, Client: EE_Company, status: 'Pending', log: [{ message: 'Created', userName: user.name, timestamp: new Date() }] }]);
    } catch (error) {
      console.error('Error adding candidates to EE role:', error);
      alert('Failed to add candidates to the EE role. Please try again.');
    }
  };

  useEffect(() => {
    const fetchcandidate = async () => {
      console.log("Profile ",profileUrl); 
      try {
        if (profileUrl && profileUrl.includes("linkedin.com/in/")) {
        
        const response = await fetch(`/api/candidates/li/${encodeURIComponent(profileUrl)}`);
        const response1 = await axios.get('/api/associations/existing-data'); 
        //setStages(response1.data.stageStatusMap || []);              
        setChangeOptionsMap(response1.data.changeOptionsMap || {});

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        setcandidate(data.candidate[0]);
        setAssoData(data.associations);
     
      }
      else {

        return (
          <div>
            <h1>Invalid URL</h1>
          </div>
        );
      } 
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
    };

    fetchcandidate();
  }, [profileUrl]);
  
/*  const handlePriority = (event) => {
    handlePriorityChange(candidate.id, event.target.value);
    setShowPriority(false);
  }

    
const handlePriorityChange = async (associationId, newStatus) => {

  try {
      // Send request to update the status
      await axios.put(`/api/associations/${associationId}/priority`, { priority: newStatus });
      
  } catch (err) {
      console.error('Error changing status:', err);
  }

}; */

 // Change status of an association
 const handleChangeStatus = async (associationId, newStatus) => {
  try {
      // Send request to update the status
      await axios.put(`/api/associations/${associationId}/status`, { status: newStatus });

      }
 catch (err) {
      console.error('Error changing status:', err);
}};


  
  const addCandidatesToRole = async () => {
    try {
      await axios.post('/api/associations/add-candidates-to-role', {
        roleId: selectedRole,
        candidateIds: [candidate._id],
        creator: user.email, // The name or ID of the creator
      });
    setAssoData([...assoData, { RoleName: clients.find(client => client.name === selectedClient)?.roles.find(role => role.id === selectedRole)?.name, Client: selectedClient, status: 'Pending', log: [{ message: 'Created', userName: user.name, timestamp: new Date() }] }]);
    } catch (error) {
      console.error('Error adding candidates to role:', error);
      alert('Failed to add candidates to the role. Please try again.');
    }
  };
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleEditClick = () => {
    document.getElementById('roleclient').style.display = 'none';
    document.getElementById('roleclientselect').style.display = 'block';
  };

  return(

    <>
      {profileUrl && profileUrl.includes("linkedin.com/in/") && 
  
  (
    <>
    <div className="profile-page">
 
          {candidate ? (
        <Box textAlign="center" m={2}>     
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
           <Avatar src={candidate.dp || ''} sx={{ width: 60, height: 60 }} />
            <Box sx={{ width: '70%'}}>
            <Typography variant="h6">{candidate.name}</Typography>           
            <Typography variant="body2">
              {candidate.currentRole} at {candidate.currentCompany} - {candidate.currentLocation}
              ( {Math.floor(candidate.experience_yrs)} Y {Math.round((candidate.experience_yrs % 1) * 12)} M )
            </Typography>
            {candidate.linkedin && (
              <IconButton component="a" href={candidate.linkedin} target="_blank">
                <LinkedIn />
              </IconButton>
            )}
            {candidate.phone && (
              <>
              <IconButton component="a" href={`tel:${candidate.phone}`}>
                <Phone />
              </IconButton>              
              <IconButton component="a" href={`https://wa.me/${candidate.phone}`} target ="_blank">
                <WhatsAppIcon />
              </IconButton>
              </>
            )}
            {candidate.email && (
              <IconButton component="a" href={`mailto:${candidate.email}`}>
                <Email />
              </IconButton>
            )}
            {candidate.resume && (
              <Button variant="contained" color="primary" onClick={() => window.open(candidate.resume, '_blank', 'noopener,noreferrer')}>
                View Resume
              </Button>
            )}
              
           </Box>
        </Box>
        <Box>
            {candidate.Tags && candidate.Tags.map((tag) => (
              <Chip key={tag} label={tag} onDelete={() => removeTags(candidate._id, tag)} />
            ))}
            <Autosuggest
              id="tags"
              options={Array.isArray(Tags) ? Tags : []}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Tags" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                addTags(candidate._id, newValue);
                newValue = '';
              }}
            />
          </Box>

         <Grid container spacing={2} sx={{ padding: 0 }}>
        <Grid item xs={4}>
          {candidate.noticePeriod && (
            <Typography variant="body2" color="textSecondary">
              Notice Period: <strong>{candidate.noticePeriod}</strong> days
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {candidate.compensation && (
            <Typography variant="body2" color="textSecondary">
              Current Salary: <strong>₹{candidate.compensation}</strong> LPA
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {candidate.expectation && (
            <Typography variant="body2" color="textSecondary">
              Expected Salary: <strong>₹{candidate.expectation}</strong> LPA
            </Typography>
          )}
        </Grid>
      </Grid>

      </Box>
      ) : (
        <p>User not found</p>
      )}
      <h2>Associations</h2>

      <div id='roleclientselect' style={{ display: 'none' }}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Client</InputLabel>
          <Select value={selectedClient} onChange={handleClientChange}>
            {clients.map(client => (
              <MenuItem key={client.name} value={client.name}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" disabled={!selectedClient}>
          <InputLabel>Role</InputLabel>
          <Select value={selectedRole} onChange={handleRoleChange}>
            {clients
              .find(client => client.name === selectedClient)?.roles
              .map(role => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
        variant="contained"
        color="primary"
        onClick={() => {
        document.getElementById('roleclient').style.display = 'block'; 
        document.getElementById('roleclientselect').style.display = 'none';
        addCandidatesToRole();
        }} > 
        Save
        </Button>
        ExecEdge
        <FormControl fullWidth margin="normal">
          <InputLabel>Client</InputLabel>
          <Select value={EE_client} onChange={(e) => setEE_client(e.target.value)}>
            {Array.isArray(EE_data) ? EE_data.map(client => (
              <MenuItem key={client._id} value={client.Client}>
                {client.Client}
              </MenuItem>
            )) : []}
          </Select>
          <Select value={EE_Company} onChange={(e) => setEE_Company(e.target.value)}>
            {Array.isArray(EE_data) && EE_data
              .find(client => client.Client === EE_client)?.TargetCompany
              .map(company => (
                <MenuItem key={company._id} value={company.Name}>
                  {company.Name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        </div>
        <div id='roleclient' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc', padding: '10px', verticalAlign: 'middle' }}>
          <Typography onClick={handleEditClick}>{clients
            .find(client => client.name === selectedClient)?.roles
            .find(role => role.id === selectedRole)?.name
        || ''} @ {selectedClient} <Button
        variant="contained"
        color="primary"
        onClick={addCandidatesToRole}
        disabled={!selectedRole}
        style={{ marginTop: '20px', float: 'right' }}
        >
        Add
        </Button></Typography>
        <Typography>{EE_client} @ {EE_Company }
          <Button onClick={() => {addToEE(EE_Company);}} 
        style={{ marginTop: '20px', float: 'right' }}>
        add EE</Button>
    </Typography>

</div>     
      <ul>
        {assoData.map((asso) => (
          <li key={asso._id}>
            <p>{asso.RoleName} @ {asso.Client}: {asso.status}</p>
            {changeOptionsMap[asso.status]?.map((option) => (
                          <Button
                            key={option}
                            size="small"
                            variant="outlined"
                            onClick={() => handleChangeStatus(asso._id, option)}
                            sx={{ mr: 1, mt: 2 }}
                          >
                            {option}
                          </Button>
                        ))}
                        
            {asso.log && asso.log.map((entry) => (
              entry.message && <p key={entry._id}>{entry.userName}: {entry.message} {entry.timestapm}</p>            
             
            ))      
            


            }
          </li>
        ))}
      </ul>
      <div style={{  
        position: 'fixed',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)'}}>
        {user.name}
      </div>
    </div>
    </>
      )}
    </>
  );
}

export default ProfilePage;
