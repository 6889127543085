import React from 'react';
import { Container, Card, CardContent, Typography, Button } from '@mui/material';

const Signup = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Card elevation={3}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        ExecEdge by HireVeda!
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Ready to Elevate Your Career to the Next Level? 🌟
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        ExecEdge by HireVeda is for senior professionals who don’t just want a job—they want a breakthrough.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        If you’re looking to:
                    </Typography>
                    <ul>
                        <li>✨ Unlock untapped opportunities</li>
                        <li>✨ Build a powerful professional network</li>
                        <li>✨ Land roles that truly match your leadership potential</li>
                    </ul>
                    <Typography variant="body1" gutterBottom>
                        This is your chance to make it happen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Opportunities are waiting. Will you seize them?
                    </Typography>
                    <Button variant="contained" color="primary" href="https://forms.gle/1yS9RT4Q16oghJDi9">
                        👉 Apply Now
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Signup;