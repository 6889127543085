import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CandidateForm from './utils/candidateForm';
import { useAuth } from './AuthContext';

const CandidateDashboard = () => {
  
  const {user}  = useAuth();

  
  return (
    <>
      {user && <CandidateForm user={user} />}
    </>
  );
  
};

export default CandidateDashboard;
