import React, { useState, useEffect } from 'react';
import { Modal,Button, Box, TextField } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { Typography } from 'antd';
import { Alert } from '@mui/material';


const SetupGmail = () => {
  const { user } = useAuth();
  const [authorizedEmails, setAuthorizedEmails] = useState([]);

  useEffect(() => {
    const fetchAuthorizedEmails = async () => {
      try {
        const response = await axios.get(`/api/emails/authorized?email=${user.email}`);
        setAuthorizedEmails(response.data);
      } catch (error) {
        console.error('Error fetching authorized emails:', error);
      }
    };
    fetchAuthorizedEmails();
  },[user]);

  const getAuthUrl = async () => {
    try {
    const currentWindowUrl = window.location.href;
    const response = await axios.get('/api/auth/google/url', {
      params: { user: user, redirect_uri: currentWindowUrl },
    });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error getting auth URL:', error);
    }
  };

const revokeGmail = async () => {
    try {
        const response = await axios.post('/api/auth/google/revoke', { email: user.email });
        if (response.data.success) {
            setAuthorizedEmails(authorizedEmails.filter(e => e !== user.email));
        } else {
            console.error('Error revoking email:', response.data.error);
        }
    } catch (error) {
        console.error('Error revoking email:', error);
    }

}
const [open, setOpen] = useState(false);
const [to, setTo] = useState('');
const [subject, setSubject] = useState('');
const [body, setBody] = useState('');

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

const handleSendEmail = async () => {
    try {
        await axios.post('/api/emails/send', {
            to,
            subject,
            body,
            from: user.email,
        });
        handleClose();
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

  return (
    <>
    <Box sx={{ margin: "0 auto", width: "100%" }}>
      {/* Email Setup Section */}     
            <Typography variant="h1"><b>Gmail Authorization</b></Typography>
        {authorizedEmails.length === 0 ? (
            <>
            <Typography variant="body1">
                Connect your Gmail account to enable HireVeda to send, track, and manage emails on your behalf. By linking your Gmail account, you can:
            </Typography>
            <ul>
                <li>Send emails directly from your personalized templates.</li>
                <li>Track email status, including opens, clicks, and replies.</li>
                <li>Resend or follow up on emails effortlessly.</li>
            </ul>
            <Typography variant="body1">
                <strong>Why do we need this?</strong> HireVeda uses your Gmail account to ensure all emails are sent from your trusted address, maintaining professionalism and authenticity in your communications.
            </Typography>
            <Typography variant="body1">
                <strong>Your Privacy is Our Priority</strong> We only access the permissions necessary to send and track emails. Your Gmail data remains secure, and we never share or store sensitive information beyond what's required for these features.
            </Typography>
            <Typography variant="body1">
                <strong>How to Connect:</strong>
            </Typography>
            <ol>
                <li>Click the "Authorize Gmail" button below.</li>
                <li>Sign in with your Gmail account and grant the required permissions.</li>
                <li>Start sending and tracking emails seamlessly through HireVeda.</li>
            </ol>
            
        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={getAuthUrl}
            sx={{ marginBottom: 2 }}
          >
            Add Gmail Account
          </Button>
        </Box>
        </>
      ) : (
        <Box>
          {authorizedEmails.map((email, index) => (
            <Typography
              key={index}
              variant="h3"
              sx={{
                marginBottom: "10px",
                padding: "8px",
                border: "1px solid #ececec",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              {email}
              
              <Button
              variant='text'
            color="warning"
              onClick={() => revokeGmail()}
              > Revoke Access </Button>
              
              <Button variant="contained" color="primary" onClick={handleOpen}>
                    Send Test Email
                </Button>
            </Typography>
            
          ))}
        </Box>
      )}
      
      <Box>  
      </Box>
      </Box>
    

                <Modal open={open} onClose={handleClose}>
                    <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%' }}>
                        <Typography variant="h6">Send Test Email</Typography>
                        <TextField
                            label="To"
                            fullWidth
                            margin="normal"
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                        />
                        <TextField
                            label="Subject"
                            fullWidth
                            margin="normal"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                        <TextField
                            label="Body"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        />
                        <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleSendEmail}>
                                Send
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </>
        );

}


    
export default SetupGmail;
