import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, Chip, Paper, TextField, img, LinearProgress } from '@mui/material';
import { LocationOn, Work, Business, Money, People,  Share } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import {useAuth} from '../AuthContext';
import 'react-phone-number-input/style.css';

const ApplyPage = () => {

  const { roleName } = useParams();  
  const [step, setStep] = useState(2); 
  const [assoId, setAssoId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    linkedin: '',
    currentRole: '',
    currentCompany: '',
    currentLocation: '',
    experience_yrs: '',
    compensation: '',
    expectation: '',
    noticePeriod: null,
    resume: null,
    assessments: []
  });
  const [role, setRole] = useState(null);
  const [errors, setErrors] = useState({});
  const [uploadedFileName, setUploadedFileName] = useState('');
  
  const {user}  = useAuth() || {};

  const fetchRoleData = async () => {
    setLoading(true);
    axios.get(`/api/role/${roleName}`)
    .then(response => {
       setRole(response.data);
       setLoading(false);
    })
    .catch(error => console.error('Error fetching role:', error));
  };

  const fetchCandidateData = async () => {
    if (user && user.role != 'guest') {
      axios.get(`/api/candidates/email/${user.email}`)
        .then(response => {          
            setForm({
              ...form,
              id: response.data[1]._id,
              _id: response.data[1]._id, 
              name: response.data[1].name || '',
              email: response.data[1].email || '',
              phone: response.data[1].phone || '',
              linkedin: response.data[1].linkedin || '',
              currentRole: response.data[1].currentRole || '',
              currentCompany: response.data[1].currentCompany || '',
              currentLocation: response.data[1].currentLocation || '',
              experience_yrs: response.data[1].experience_yrs || '',
              compensation: response.data[1].compensation || '',
              expectation: response.data[1].expectation || '',
              noticePeriod: response.data[1].noticePeriod || null,
              resume: response.data[1].resume || null,
              assessments: response.data[1].assessments || []
            });          
        })
        .catch(error => console.error('Error fetching candidate data:', error));
      }
  };

  // Fetch role data
  useEffect(() => {
    fetchRoleData();
    fetchCandidateData();
    }, [roleName, user]);

  // Handle field changes and validation
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    
    let newErrors = { ...errors };
    if (name === 'name' && !value) {
      newErrors.name = 'Name is required';}
    else
    if (name === 'phone' && !validatePhone(value)) {
      newErrors.phone = 'Invalid phone number';
    } else if (name === 'email' && !validateEmail(value)) {
      newErrors.email = 'Invalid email address';
    } else if (name === 'linkedin' && !validateLinkedInUrl(value)) {
      newErrors.linkedin = 'Invalid LinkedIn URL (e.g. https://www.linkedin.com/in/username)';
    } else if (name === 'experience_yrs' && (!value || isNaN(value) || value < 0 || value > 61)) {
      newErrors.experience_yrs = 'Invalid experience years value (0-60)' + value;
    } else if (name === 'compensation' && (!value || isNaN(value) || value < 0 || value > 2000)) {
      newErrors.compensation = 'Invalid compensation value (0-1000) Lacs enter "20" for 20 Lacs';
    } else if (name === 'expectation' && (!value || isNaN(value) || value < 0 || value > 2000)) {
      newErrors.expectation = 'Invalid expected compensation value (0-1000) Lacs enter "20" for 20 Lacs';
    } else if (name === 'noticePeriod' && (!value || isNaN(value))) {
      newErrors.noticePeriod = 'Invalid notice period value (0-90). Select 0 for immediate joining';
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  // Validation functions
  const validatePhone = (phone) => phone && phone.length > 8;
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateLinkedInUrl = (linkedinUrl) => {
    const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/i;
    return pattern.test(linkedinUrl);
  };

  const correctLinkedInUrl = (linkedinUrl) => {
    if (!linkedinUrl) return linkedinUrl;
    if (linkedinUrl.startsWith('http://') || linkedinUrl.startsWith('https://')) {
      return linkedinUrl;
    }
    if (linkedinUrl.startsWith('linkedin.com') || linkedinUrl.startsWith('www.linkedin.com')) {
      return `https://${linkedinUrl.replace(/^www\./, '')}`;
    }
    if (linkedinUrl.startsWith('in/')) {
      return `https://www.linkedin.com/${linkedinUrl}`;
    }
    return `https://www.linkedin.com/in/${linkedinUrl}`;
  };

  // Upload resume
  const handleFileUpload = async (event) => {
    
    const file = event.target.files[0];

    if (file) {
      setUploadedFileName(file.name);
      setForm({ ...form, resume: file });

      // Upload the file to the backend immediately
      const formData = new FormData();
      formData.append('resume', file);
      try {
        setLoading(true);
        const response = await axios.post('/api/upload/resume', formData);
        console.log('Resume uploaded successfully', response.data);
        const filePath = response.data.filePath; // Adjust according to your API response structure
        setForm({ ...form, resume: filePath });
        setLoading(false);
      } catch (error) {
        console.error('Error uploading resume:', error);
      }
    }
  };

  const saveCandidateData = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/candidates', form);
      if (response.data && response.data._id) {
        // Update the form with the saved candidate's ID
        setForm((prevForm) => ({ ...prevForm, id: response.data._id }));
        console.log('Candidate saved with ID:', response.data._id); // Add log for debugging
        setLoading(false);
        return response.data._id;
      } else {
        console.error('Error: Candidate ID not found in response.');
        return null;
      }
    } catch (error) {
      console.error('Error saving candidate:', error);
      return null;
    }
  };
  
  const associateCandidateWithRole = async () => {
    if (!form.id) {
      console.error('Candidate ID is missing. Cannot associate with role.');
      return;
    }
  
    try {
      setLoading(true);
      const response = await axios.post('/api/associations/add-candidates-to-role', {
        candidateIds: [form.id], // Use the correct candidate ID
        roleId: role._id, // Pass the role ID
        creator: 'hv app' // Example creator, modify as needed
      });
      setAssoId(response.data[0]._id);
      setLoading(false);
      console.log('Candidate associated with role successfully:',response.data[0]._id);
    } catch (error) {
      console.error('Error associating candidate with role:', error);
    }
  }; 
 
    // Trigger association as soon as candidate ID is available
    useEffect(() => {
      if (form.id) {
        associateCandidateWithRole(); // Call associate function when candidate ID is set
      }
    }, [form.id]);

    const handleNextClick = async () => {
      let newErrors = {};
    
      if (!form.name) newErrors.name = 'Name is required';
      if (!validateEmail(form.email)) newErrors.email = 'Invalid email address';
      if (!validatePhone(form.phone)) newErrors.phone = 'Invalid phone number';
    
      if (step === 3) {
        
      if (!validateLinkedInUrl(form.linkedin)) newErrors.linkedin = 'Invalid LinkedIn URL';

        if (!form.experience_yrs || isNaN(form.experience_yrs) || form.experience_yrs < 0 || form.experience_yrs > 60) {
          newErrors.experience_yrs = 'Invalid experience value';
        }
        if (!form.compensation || isNaN(form.compensation) || form.compensation < 0 || form.compensation > 2000) {
          newErrors.compensation = 'Invalid compensation value (0-1000) Lacs enter "20" for 20 Lacs';
        }
        if (!form.expectation || isNaN(form.expectation) || form.expectation < 0 || form.expectation > 2000) {
          newErrors.expectation = 'Invalid compensation value (0-1000) Lacs enter "20" for 20 Lacs';
        }
        if (!form.noticePeriod || isNaN(form.noticePeriod)) {
          newErrors.noticePeriod = 'Please select notice period';
        }
        setErrors(newErrors);
      }
      if (step === 4) {
        role.Assessment.forEach((question, index) => {
          if (!form.assessments[index]) {
            newErrors[`assessments[${index}]`] = 'Answer required';
          }
        });
        if (Object.keys(newErrors).length > 0) {
          console.error('Errors in form, cannot proceed');
          setErrors(newErrors);
          return;
        }
        else {
          axios.post('/api/associations/save-assessment', {
            assoId: assoId,
            assessment: form.assessments
          })
          .then(response => {
            console.log('Assessment saved successfully:', response.data);
          })
          .catch(error => console.error('Error saving assessment:', error));
          
          }
      setErrors(newErrors);
        }

    
      if (Object.keys(newErrors).length === 0) {
        if (step === 3 ) {
          const candidateId = await saveCandidateData();
          if (candidateId) {
            setForm((prevForm) => ({ ...prevForm, id: candidateId }));
            await associateCandidateWithRole();
          }
        }
        setStep(step + 1);

      if (step === 3 )// && (!role?.Assessment || role.Assessment.length === 0)) 
        {
        setStep(5); // Skip to step 5 if there are no additional questions
      }

      } 
      
      else {
        console.error('Form contains errors, cannot proceed');
      }
    };
    
    const handleShare = (role) => {
      const shareUrl = `https://hireveda.com/apply?${role.RoleName}`;
      navigator.clipboard.writeText(shareUrl);
      alert('Job link copied to clipboard!');
    };
  

  return (
    <>
    <div>
    {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, zIndex: 1200 }}>
          <LinearProgress />
        </Box>
      )}
      </div>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 4, mt: 4 }}>
        <div className="job-header" style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          borderBottom: '1px solid #ddd', 
          paddingBottom: '10px', 
          marginBottom: '20px'  
        }}>          
          <div>
            <h1>{role?.RoleName || 'Job Title'}</h1>
            {role?.RoleType +' | ' || ''}  
            {role?.Location?.replace(/\n/g, ' ')}
          </div>
          <div className="logo-container">
              <img src="https://hireveda.com/content/images/size/w1000/2023/05/HireVeda-6.png" alt="Logo" className="logo" 
              style=  {{
                height: 40+'px', 
                marginRight: 10+'px',
                }} />
          </div>
      </div>
          {step === 1 && (
            <>
              <Typography variant="body1">
              </Typography>
              <Button variant="contained" color="primary" onClick={() => setStep(2)} fullWidth sx={{ mt: 3 }}>
                Apply Now
              </Button> 
            </>
          )}

          {step === 2 && (
            <>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={form.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name || ''}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={form.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email || ''}
                margin="normal"
                required
              />
              <div style={{ marginTop: '16px', marginBottom: '8px' }}>
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={form.phone}
                  onChange={(value) => {
                    setForm({ ...form, phone: value });
                    if (!validatePhone(value)) {
                      setErrors({ ...errors, phone: 'Invalid phone number' });
                    } else {
                      setErrors({ ...errors, phone: '' });
                    }
                  }}
                />
                {errors.phone && <Typography color="error">{errors.phone}</Typography>}
              </div>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ mt: 2 }}
              >
                Upload Resume
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
              {uploadedFileName && <Typography>Uploaded File: {uploadedFileName}</Typography>}

              <Button variant="contained" color="primary" onClick={handleNextClick} fullWidth sx={{ mt: 3 }}>
                Next
              </Button>
            </>
          )}

          {step === 3 && (
            <>
              <TextField
                fullWidth
                label="Current Designation"
                name="currentRole"
                value={form.currentRole}
                onChange={handleChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Current Organization"
                name="currentCompany"
                value={form.currentCompany}
                onChange={handleChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Current Location"
                name="currentLocation"
                value={form.currentLocation}
                onChange={handleChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Total Years of experience"
                name="experience_yrs"
                value={form.experience_yrs}
                onChange={handleChange}
                error={!!errors.experience_yrs}
                helperText={errors.experience_yrs || ''}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Current Compensation (INR Lacs)"
                name="compensation"
                value={form.compensation}
                onChange={handleChange}
                error={!!errors.compensation}
                helperText={errors.compensation || ''}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Expected Compensation (INR Lacs)"
                name="expectation"
                value={form.expectation}
                onChange={handleChange}
                error={!!errors.expectation}
                helperText={errors.expectation || ''}
                margin="normal"
              />
              
              <TextField
                select
                fullWidth
                label="Notice Period (Days)"
                name="noticePeriod"
                value={form.noticePeriod}
                onChange={handleChange}
                error={!!errors.noticePeriod}
                helperText={errors.noticePeriod || ''}
                margin="normal"
                SelectProps={{
                  native: true,
                }}
              >
                <option>Select</option>    
                <option value={0}>0 Days</option>
                <option value={15}>15 Days</option>
                <option value={30}>30 Days</option>
                <option value={60}>60 Days</option>
                <option value={90}>90 Days</option>
                <option value={99}>90+ Days</option>
              </TextField>
              
              <TextField
                fullWidth
                label="LinkedIn URL"
                name="linkedin"
                value={form.linkedin}
                onChange={handleChange}
                error={!!errors.linkedin}
                helperText={errors.linkedin || ''}
                margin="normal"
              />
              
              <Button variant="contained" color="primary" onClick={handleNextClick} fullWidth sx={{ mt: 3 }}>
                Next
              </Button>
            </>
          )}

          {step === 4 && (
            <>
              <Typography variant="h6">Additional Questions</Typography>
              {role?.Assessment?.map((question, index) => {
                switch (question.type) {
                  case 'Descriptive':
                    return (
                      <TextField
                        key={index}
                        fullWidth
                        label={question.Question}
                        name={`assessments[${index}]`}
                        value={form.assessments[index] || ''}
                        onChange={(e) => {
                          const newAnswers = [...form.assessments];
                          newAnswers[index] = {
                            question: question.Question,
                            type: question.type,
                            answere:  e.target.value
                          };
                          setForm({ ...form, assessments: newAnswers });
                        }}
                        margin="normal"
                      />
                    );
                  case 'Select':
                    return (
                      <TextField
                        key={index}
                        select
                        fullWidth
                        label={question.Question}
                        name={`assessments[${index}]`}
                        value={form.assessments[index] || ''}
                        onChange={(e) => {
                          const newAnswers = [...form.assessments];
                          newAnswers[index] = e.target.value;
                          setForm({ ...form, assessments: newAnswers });
                        }}
                        margin="normal"
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {question.options.map((option, optIndex) => (
                          <option key={optIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                    );
                  case 'Multi Select':
                    return (
                      <TextField
                        key={index}
                        select
                        fullWidth
                        label={question.Question}
                        name={`assessments[${index}]`}
                        value={form.assessments[index] || ''}
                        onChange={(e) => {
                          const newAnswers = [...form.assessments];
                          newAnswers[index] = e.target.value;
                          setForm({ ...form, assessments: newAnswers });
                        }}
                        margin="normal"
                        SelectProps={{
                          multiple: true,
                          native: true,
                        }}
                      >
                        {question.options.map((option, optIndex) => (
                          <option key={optIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                    );
                  case 'Check Box (Y/N)':
                    return (
                      <div key={index}>
                        <Typography>{question.Question}</Typography>
                        <label>
                          <input
                            type="checkbox"
                            name={`assessments[${index}]`}
                            checked={form.assessments[index] === 'Yes'}
                            onChange={(e) => {
                              const newAnswers = [...form.assessments];
                              newAnswers[index] = e.target.checked ? 'Yes' : 'No';
                              setForm({ ...form, assessments: newAnswers });
                            }}
                          />
                          Yes
                        </label>
                      </div>
                    );
                    return null;
                }
              })}
              <Button variant="contained" color="primary" onClick={handleNextClick} fullWidth sx={{ mt: 3 }}>
                Submit Application
              </Button>
            </>
          )}

          {step === 5 && (
            <>
              <Typography variant="h5">Thank You for Applying!</Typography>
              <br/>
              <Typography variant="body1">You can share this role opportunity with others.</Typography>
              <br/>
              <Button variant="outlined" color="primary" startIcon={<Share />} onClick={() => handleShare(role)}>Share</Button>
              <br/>              
              <Typography variant="h6">Due to the high number of applications, we’ll be able to connect only with candidates whose profiles closely match the role requirements. Thank you for your understanding!</Typography>

            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ApplyPage;
