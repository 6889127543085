import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const Theme = (darkMode) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: darkMode ? 'dark' : 'light',
        primary: {
          main: darkMode ? '#0A84FF' : '#003B73', // Modern blue tones
          contrastText: darkMode ? '#FFFFFF' : '#FFFFFF',
        },
        secondary: {
          main: darkMode ? '#00C896' : '#FFC400', // Vibrant secondary tones
          contrastText: darkMode ? '#1A1A1A' : '#2C2C2C',
        },
        background: {
          default: darkMode ? '#121212' : '#F9FAFB', // Soft contrast backgrounds
          paper: darkMode ? '#1E1E1E' : '#FFFFFF', // For cards and modals
        },
        text: {
          primary: darkMode ? '#EDEDED' : '#1E293B',
          secondary: darkMode ? '#B0B0B0' : '#475569',
        },
        accent: {
          main: darkMode ? '#FFB300' : '#FFC107', // Accent for small highlights
        },
        divider: darkMode ? '#333333' : '#E5E7EB',
      },
      typography: {
        fontFamily: '"Inter", "Poppins", "Roboto", sans-serif',
        h1: {
          fontSize: '3.4rem',
          fontWeight: 800,
          color: darkMode ? '#EDEDED' : '#002F63',
          letterSpacing: '-0.04em',
        },
        h2: {
          fontSize: '2.8rem',
          fontWeight: 700,
          color: darkMode ? '#DADADA' : '#003B73',
          letterSpacing: '-0.03em',
        },
        h3: {
          fontSize: '2.4rem',
          fontWeight: 600,
          color: darkMode ? '#E6E6E6' : '#202020',
        },
        body1: {
          fontSize: '1rem',
          lineHeight: 1.75,
        },
        button: {
          fontWeight: 700,
          fontSize: '0.95rem',
          textTransform: 'capitalize',
          letterSpacing: '0.05em',
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              background: darkMode
                ? 'linear-gradient(90deg, #1A1A1A, #292929)'
                : 'linear-gradient(90deg, #003B73, #004E8A)',
              boxShadow: darkMode
                ? '0 4px 12px rgba(0, 0, 0, 0.7)'
                : '0 4px 12px rgba(0, 59, 115, 0.3)',
              borderBottom: darkMode ? '1px solid #333333' : '1px solid #E5E7EB',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              padding: '8px 18px',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
              ':hover': {
                backgroundColor: darkMode ? '#0058CC' : '#0050A0',
              },
            },
            containedPrimary: {
              background: darkMode
                ? 'linear-gradient(45deg, #0A84FF, #057DFF)'
                : 'linear-gradient(45deg, #00498B, #003B73)',
              color: '#FFFFFF',
              ':hover': {
                background: darkMode
                  ? 'linear-gradient(45deg, #057DFF, #005BBB)'
                  : 'linear-gradient(45deg, #003B73, #002952)',
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: 16,
              backgroundImage: darkMode
                ? 'linear-gradient(145deg, #1E1E1E, #1A1A1A)'
                : 'linear-gradient(145deg, #FFFFFF, #F9FAFB)',
              boxShadow: darkMode
                ? '0px 8px 24px rgba(0, 0, 0, 0.8)'
                : '0px 8px 24px rgba(0, 59, 115, 0.2)',
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h1: {
              fontSize: '3.4rem',
              fontWeight: 900,
              color: darkMode ? '#EDEDED' : '#003B73',
            },
            h2: {
              fontSize: '2.8rem',
              fontWeight: 800,
              color: darkMode ? '#DADADA' : '#003B73',
            },
          },
        },
      },
    MuiFooter: {
      styleOverrides: {
        root: {
          background: darkMode
            ? 'linear-gradient(90deg, #1A1A1A, #292929)'
            : 'linear-gradient(90deg, #003B73, #004E8A)',
          boxShadow: darkMode
            ? '0 4px 12px rgba(0, 0, 0, 0.7)'
            : '0 4px 12px rgba(0, 59, 115, 0.3)',
          borderBottom: darkMode ? '1px solid #333333' : '1px solid #E5E7EB',
        },
      },
    },
    })
  );

export default Theme;
