// Import necessary libraries
import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  IconButton,
  Chip,
  Tooltip,
} from "@mui/material";
import { FilterList, Info, Refresh, Visibility } from "@mui/icons-material";
import axios from "axios";
import ComposeEmail from './ComposeEmail';

const Dashboard = () => {
  const [emails, setEmails] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isComposeOpen, setIsComposeOpen] = useState(false);

  const handleComposeOpen = () => {
    setIsComposeOpen(true);
  };

  const handleComposeClose = () => {
    setIsComposeOpen(false);
  };

  const handleEmailSubmit = async (emailData) => {
    try {
      const response = await fetch("/api/emails/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        alert("Email successfully sent or scheduled!");
      } else {
        alert("Failed to send or schedule email!");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending or scheduling the email.");
    }
  };

  const fetchEmails = async (page = 1) => {
    try {
      const response = await axios.get(`/api/emails`, {
        params: { page, status: filterStatus, search: searchTerm },
      });
      setEmails(response.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch emails", error);
    }
  };

  useEffect(() => {
    fetchEmails(currentPage);
  }, [currentPage, filterStatus, searchTerm]);

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    fetchEmails(currentPage);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>

      <Typography variant="h4" gutterBottom>
        Email Dashboard
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" gap={2}>
          {/* Filter by Status */}
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={filterStatus}
              onChange={handleFilterChange}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="sent">Sent</MenuItem>
              <MenuItem value="paused">Paused</MenuItem>
              <MenuItem value="failed">Failed</MenuItem>
            </Select>
          </FormControl>

          {/* Search by Subject */}
          <TextField
            label="Search by Subject"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Box>

        <Tooltip title="Refresh">
          <IconButton onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Email Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subject</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails?.map((email) => (
              <TableRow key={email._id}>
                <TableCell>{email.subject}</TableCell>
                <TableCell>
                  <Chip
                    label={email.status}
                    color={
                      email.status === "sent"
                        ? "success"
                        : email.status === "failed"
                        ? "error"
                        : "warning"
                    }
                  />
                </TableCell>
                <TableCell>{Array.isArray(email.to) ? email.to.join(", ") : email.to}</TableCell>
                <TableCell>{
                  email.sentAt
                    ? new Date(email.sentAt).toLocaleString()
                    : "Not Sent"
                }</TableCell>
                <TableCell align="right">
                  <Tooltip title="View Details">
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Info">
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box mt={3} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleComposeOpen}>
        Compose Email
      </Button>
      <ComposeEmail
        open={isComposeOpen}
        onClose={handleComposeClose}
        onSubmit={handleEmailSubmit}
      />
    </Container>
  );
};

export default Dashboard;
