import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button
} from '@mui/material';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import Dashboard from './Dashboard';
import EmailTemplateManager from './EmailTemplateManager';
const EmailCampaignDashboard = () => {
  const { user } = useAuth();
  const [authorizedEmails, setAuthorizedEmails] = useState([]);

  const getAuthUrl = async () => {
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    const response = await axios.get('/api/auth/google/url',
      { params: { user: user } }
    );
    window.location.href = response.data.url; // Redirect the user to Google's consent screen
  };

  useEffect(() => {
    // Fetch authorized emails
    const fetchAuthorizedEmails = async () => {
      if (!user) {
        console.error('User is not authenticated');
        return;
      }
      const response = await axios.get(`/api/emails/authorized?id=${user.id}`);
      setAuthorizedEmails(response.data);
    };
    fetchAuthorizedEmails();
  }, [user]);
  
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Google Gmail Integration
          </Typography>
        </Toolbar>
      </AppBar>

          <Button variant="contained" color="primary" onClick={() => getAuthUrl()}>
            Login with Google
          </Button>
      <Dashboard />
      <EmailTemplateManager />
    </div>
  );
};

export default EmailCampaignDashboard;
