import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Card, Button, TextField, Typography, FormControl, CardContent, CardHeader, Autocomplete, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import OrgForm from '../utils/OrgForm';
import CompanyLogo from '../CompanyLogo';
import { useAuth } from '../AuthContext';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SelfAssessment from './SelfAssessment';
import Signup from './signup';
import Reachouts from './Reachouts';
import SetupGmail from '../utils/setupgmail';
import { Modal } from 'antd';

const Execedge = () => {
    const { user } = useAuth();
    const [targetCompanies, setTargetCompanies] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [newCompany, setNewCompany] = useState({ name: '', organization: '', status: '' });
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [OrgEdit, setOrgEdit] = useState('');
    const [Project, setProject] = useState([]);
    const [decisionMakers, setDecisionMakers] = useState([]);
    const [AllProject, setAllProject] = useState([]);
    const [AddNew, setAddNew] = useState(0);
    const [newClient, setNewClient] = useState({ email: '', client: '' });
    const [openSetupGmail, setOpenSetupGmail] =  useState(0);

    const [showComments, setShowComments] = useState('');
    const [newComment, setNewComment] = useState({});

    const fetchAuthorizedEmails = async () => {
        try {
          const response = await axios.get(`/api/emails/authorized?email=${user.email}`);
          return(response.data);
        } catch (error) {
          console.error('Error fetching authorized emails:', error);
        }
      };

    useEffect(() => {
            setEmail(user.email);    
            if(user.role === "admin"){
                getAllProjects();
            }  
    }, [user]);      

    useEffect(() => {
        const checkAuthorizedEmails = async () => {
            const authorizedEmails = await fetchAuthorizedEmails();
            if (!authorizedEmails || authorizedEmails.length === 0) {
                console.log("Show setup Gmail");
                setOpenSetupGmail(true);
            }
        };

        checkAuthorizedEmails();
    }, []);

    const handleAddClient = async () => {
        try {
            await axios.post('/api/execedge/newClient', newClient);
            setNewClient({ email: '', client: '' });
            getAllProjects();
        } catch (error) {
            console.error('Error adding new client:', error);
        }
    };

    const getAllProjects = async () => {
        try {
            const response = await axios.get('/api/execedge/getAll');
            setAllProject(response.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching target companies:', error);
        }
    };

    
    const fetchTargetCompanies = useCallback(async () => {
        try {
            if (!email) return;
            const response = await axios.get(`/api/execedge/get?email=${email}`);
            if(!response.data  && user.role !== "admin")
               { console.log("Show sign up");
                return(  <Signup /> )
               }
            else
            setProject(response.data || []);
            setTargetCompanies(response.data.TargetCompany || []);
            setLoading(false);
        } catch (error) {   
            console.error('Error fetching target companies:', error);
        }
    }, [email]);

    useEffect(() => {
        fetchTargetCompanies();
    }, [fetchTargetCompanies]);


    const fetchDecisionMakers = useCallback(async () => {
        try {
            if (!Project) return;
            if (!Project._id) return;
            const id=Project._id;
            const response = await axios.get(`/api/execedge/Aassociations/${id}`);
            setDecisionMakers(response.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching target companies:', error);
        }
    }, [Project]);


    useEffect(() => {
        fetchDecisionMakers();
    }, [fetchDecisionMakers]);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await axios.get('/api/organizations');
                setOrganizations(response.data);
            } catch (error) {
                console.error('Error fetching organizations:', error);
            }
        };

        fetchOrganizations();
    }, []);

    const handleStatusChange = async (companyName, newStatus, Priority="") => {
        try {
            await axios.post('/api/execedge/updateTargetCompanyStatusByEmail', {
                email,
                Name: companyName,
                Status: newStatus,
                Priority: Priority
            });
            setTargetCompanies(prevCompanies =>
                prevCompanies.map(company =>
                    company.Name === companyName ? { ...company, Status: newStatus } : company
                )
            );
        } catch (error) {
            console.error('Error updating company status:', error);
        }
    };

   
        const [openModal, setOpenModal] = useState(false);
        const [selectedCompany, setSelectedCompany] = useState(null);
        const [newStatus, setNewStatus] = useState('');
        const [reason, setReason] = useState('');
        const [CandidateId, setCandidateId] = useState('');

        const handleOpenModal = (company, status, candidateId="") => {
            setSelectedCompany(company);
            setCandidateId(candidateId);
            setNewStatus(status);
            setOpenModal(true);
        };

        const handleCloseModal = () => {
            setOpenModal(false);
            setSelectedCompany(null);
            setCandidateId('');
            setNewStatus('');
            setReason('');
        };

        const StatusChangeWithComment = async (company, status, Priority="", candidateId="") => {
            handleOpenModal(company, status, candidateId);
        }
        const handleSubmit = async () => {
            try {
                if(CandidateId !== ""){
                    await axios.post('/api/execedge/updateConnectionStatusByEmail', {
                        ExecEdgeId: Project._id,
                        candidateId: CandidateId,
                        Status: newStatus
                    });
                    setDecisionMakers(prevDecisionMakers =>
                        prevDecisionMakers.map(decisionMaker =>
                            decisionMaker.candidateId._id === CandidateId ? { ...decisionMaker, status: newStatus } : decisionMaker
                        )
                    );
                    await handleAddCommentdecisionMaker(CandidateId);
                    
                }
            else
                    {
                await axios.post('/api/execedge/updateTargetCompanyStatusByEmail', {
                    email,
                    Name: selectedCompany.Name,
                    Status: newStatus,
                });
                setTargetCompanies(prevCompanies =>
                    prevCompanies.map(company =>
                        company.Name === selectedCompany.Name ? { ...company, Status: newStatus } : company
                    )
                );
                await handleAddComment(selectedCompany._id);
            }
                handleCloseModal();
            } catch (error) {
                console.error('Error updating company status:', error);
            }
        };


    const handleConnectionStatusChange = async (candidateId, newStatus) => {
        try {
            await axios.post('/api/execedge/updateConnectionStatusByEmail', {
                ExecEdgeId: Project._id,
                candidateId,
                Status: newStatus
            });
            setDecisionMakers(prevDecisionMakers =>
                prevDecisionMakers.map(decisionMaker =>
                    decisionMaker.candidateId._id === candidateId ? { ...decisionMaker, status: newStatus } : decisionMaker
                )
            );
        } catch (error) {
            console.error('Error updating connection status:', error);
        }
    };

    const handleAddCommentdecisionMaker = async (decisionMakerId) => {
        try {
            await axios.post('/api/execedge/addAssoComment', {
                ExecEdgeId: Project._id,
                decisionMakerId,
                comment: newComment
            });
            setNewComment({});
            fetchDecisionMakers();
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    }

    const handleAddCompany = async () => {
        try {
            await axios.post('/api/execedge/addTargetCompanyByEmail', {
                email,
                Name: newCompany.name,
                Organization: newCompany.organization,
                Status: 'Pending Approval'
            });
            fetchTargetCompanies();
        } catch (error) {
            console.error('Error adding new company:', error);
        }
    };
    const [activeTab, setActiveTab] = useState('Target Companies');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    const handleAddComment = async (companyId) => {
        try {
            await axios.post('/api/execedge/addComment', {
                email,
                companyId,
                comment: newComment
            });
            setNewComment({});
            fetchTargetCompanies();
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    }

    return (
        <>
           
           <Container maxWidth="lg" sx={{ py: 4 }}>
    { (user.role === "admin") && (
                    <Card elevation={3}>
                        <CardContent>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <Autocomplete
                                    options={AllProject.map(project => project.email)}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label="User Email" />}
                                    onChange={(e, newValue) => setEmail(newValue)}
                                />                                
                            <Button onClick={() => setAddNew(true)}> New Client</Button>
                            </FormControl>
                        </CardContent>
                        <CardContent  style={{ display: AddNew ? 'block' : 'none' }}>
                            <Typography variant="h6" gutterBottom>
                                Add New Client
                            </Typography>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <TextField
                                    label="Email"
                                    value={newClient.email || ''}
                                    onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Client"
                                    value={newClient.Client || ''}
                                    onChange={(e) => setNewClient({ ...newClient, Client: e.target.value })}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddClient}
                                sx={{ mt: 2 }}
                            >
                                Add Client
                            </Button>
                        </CardContent>
                    </Card>

                )  //*/  
                }
    { (Project.Client) && (
        <Reachouts decisionMakers={decisionMakers} Project={Project} fetchDecisionMakers={fetchDecisionMakers}/> 
        /*
            <>
                <CardHeader 
                    action={
                        <div style={{ display: 'flex', gap: '10px' }}>
                            {[ //'Self Assessment',
                             'Prospecting','Target Companies', 'Decision Makers', 'Reachouts'].map((tab, index) => (
                                <Button
                                    key={index}
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        borderRadius: '20px',
                                        textTransform: 'none',
                                        minWidth: '120px',
                                        backgroundColor: activeTab === tab ? 'secondary.main' : 'primary.main',
                                        '&:hover': {
                                            backgroundColor: activeTab === tab ? 'secondary.dark' : 'primary.dark'
                                        }
                                    }}
                                    onClick={() => handleTabChange(tab)}
                                >
                                    {tab}
                                </Button>
                            ))}
                        </div>
                    }
                   // title={activeTab}
                    sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                        '& .MuiCardHeader-title': {
                            fontSize: '1.5rem',
                            fontWeight: 600
                        }
                    }}
                />
                {
                activeTab === 'Self Assessment' && (<SelfAssessment email={email} SelfAssessment={Project.SelfAssessment} />)
                }
                {
                (activeTab === 'Target Companies' || activeTab === 'Prospecting') && (
                    <>
                    { 
                    activeTab === 'Target Companies' && (
                    <Card sx={{ mt: 4, p: 3 }}>
                        <Typography variant="h6" gutterBottom>Add New Company</Typography>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <Autocomplete
                                options={organizations}
                                getOptionLabel={(option) => option.name || option}
                                renderInput={(params) => <TextField {...params} label="Add a Company" />}                                                        
                                onInputChange={(e, newValue) => setNewCompany({ ...newCompany, organization: newValue?.name ? newValue.name : newValue, name: newValue })}
                                freeSolo
                            />
                        </FormControl>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleAddCompany}
                            sx={{ mt: 2 }}
                        >
                            Add Company
                        </Button>
                    </Card>
                    )}
                <CardContent>
                    {loading ? (
                        <Typography variant="h6" sx={{ textAlign: 'center', py: 4 }}>Loading...</Typography>
                    ) : (targetCompanies && targetCompanies.length > 0 ? (
                        <div style={{ display: 'grid', gap: '20px' }}>
                            {
                            targetCompanies.map(company => company && (
                                (activeTab === 'Prospecting' ? 
                                    ['Pending Approval', 'Disapproved', 'Hold for Later'].includes(company.Status) : 
                                    !['Pending Approval', 'Disapproved', 'Hold for Later'].includes(company.Status)
                                ) && 
                                <Card key={company._id} variant="outlined" sx={{ p: 2 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '16px' }}>
                                        <CompanyLogo companyName={company?.Name} sx={{ width: '60px', height: '60px' }} />
                                        <div>
                                            <Typography variant="h6" component="h2">{company?.Name}</Typography>
                                            <Typography color="text.secondary">{company?.Industry || ''}</Typography>
                                            <Typography>{company?.Status}</Typography>
                                            <Typography 
                                                component="a" 
                                                href={company?.Website} 
                                                target="_blank" 
                                                sx={{ textDecoration: 'none', color: 'primary.main' }}
                                            >
                                                {company?.Website}
                                            </Typography>
                                        </div>
                                        {user.role === "admin" && (
                                        <Button 
                                            onClick={() => setOrgEdit(company?.Organization)}
                                            sx={{ marginLeft: 'auto' }}
                                            startIcon={<EditIcon />}
                                        >
                                            Edit
                                        </Button>)}
                                    </div>
                                    <Typography sx={{ mb: 2 }}>{company?.Description}</Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        {company?.status}
                                    </Typography>



                                    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                        {(company.Status === 'Pending Approval' || company.Status === 'Disapproved' || company.Status ===`Hold for Later`) && (
                                            <>
                                            <Button                                            
                                            variant='outlined'
                                            size="small"
                                            onClick={() => {
                                                document.getElementById(`div-${company._id}`).style.display = 'block';
                                                document.getElementById(`button-${company._id}`).style.display = 'none';
                                                document.getElementById(`buttonD-${company._id}`).style.display = 'none';
                                            }} 
                                            id={`button-${company._id}`}
                                            > 
                                            Approved 
                                            </Button>
                                            <div id={`div-${company._id}`} style={{ display: 'none' }}>
                                            <Button 
                                            variant='outlined'
                                            size="small"
                                            onClick={() => handleStatusChange(company.Name, 'Sent for Reserch',1)} //Disapproved	Hold for Later
                                            sx={{
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                minWidth: '120px'
                                            }}
                                        >
                                        Priorty 1 
                                        </Button><Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => handleStatusChange(company.Name, 'Sent for Reserch',2)} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                            Priorty 2 
                                            </Button><Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => handleStatusChange(company.Name, 'Sent for Reserch',3)} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Priorty 3
                                            </Button>
                                            </div>
                                            </>)
                                        }
                                        {
                                            (company.Status === `Pending Approval`) &&
                                            (
                                                <>
                                                <Button                                            
                                            variant='outlined'
                                            size="small"
                                            onClick={() => {
                                                document.getElementById(`divD-${company._id}`).style.display = 'block';
                                                document.getElementById(`button-${company._id}`).style.display = 'none';
                                                document.getElementById(`buttonD-${company._id}`).style.display = 'none';
                                            }} 
                                            id={`buttonD-${company._id}`}
                                            > 
                                            Reject 
                                            </Button>
                                            <div id={`divD-${company._id}`} style={{ display: 'none' }}>
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Disapproved')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Disapproved
                                            </Button>
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Hold for Later')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Hold for Later
                                            </Button>     
                                            </div>                                            
                                            </>
                                            )
                                        }
                                        {
                                        (company.Status === `Hold for Later` ) &&
                                            (
                                                <>
                                                
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Disapproved')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Disapproved
                                            </Button>
                                            </>
                                        )}
                                        {
                                        (company.Status === `Disapproved` ) &&
                                            (
                                                <>
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => StatusChangeWithComment(company, 'Hold for Later')} //Priorty 1 Approved	Priorty 2 Approved	Priority 3 Approved	Disapproved	Hold for Later
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Hold for Later
                                            </Button>
                                            </>
                                        )}
                                        {company.Status === 'Reserch Compleated' && (
                                            <Button 
                                                variant='outlined'
                                                size="small"
                                                onClick={() => handleStatusChange(company.Name, 'Reachout Initiated')}
                                                sx={{
                                                    borderRadius: '20px',
                                                    textTransform: 'none',
                                                    minWidth: '120px'
                                                }}
                                            >
                                                Reachout Initiated
                                            </Button>
                                        )}
                                        

                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => setShowComments(company._id)}
                                            sx={{
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                minWidth: '120px',
                                                marginLeft: 'auto'
                                            }}
                                            startIcon={<EditIcon />}
                                        >
                                           {company.comments?.length || '' }  Comments
                                        </Button>
                                    </div>

                                    <div style={{ marginTop: '16px' }}>                                        
                                        {showComments === company._id && (
                                            <div style={{ marginTop: '16px' }}>
                                                {company.comments.map((comment, index) => (
                                                    <Card key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <b>{comment.userName}</b> * {new Date(comment.timestamp).toLocaleString()}
                                                        </Typography>
                                                        <Typography variant="body1">{comment.comment}</Typography>
                                                    </Card>
                                                ))}
                                                <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Add a comment"
                                                    multiline
                                                    rows={2}
                                                    value={newComment?.comment || ''}
                                                    onChange={(e) => 
                                                    setNewComment({
                                                        comment: e.target.value,
                                                        user: user._id,
                                                        userName: user.name,
                                                        timestamp: new Date()
                                                    })}
                                                    sx={{ mt: 2 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleAddComment(company._id)}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Add Comment
                                                </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Card>
                            ))}
                        </div>
                    ) : (
                        <Typography variant="h6" sx={{ textAlign: 'center', py: 4 }}>No companies found</Typography>
                    ))}

                    <div style={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: '400px',
                        height: '100%',
                        backgroundColor: '#fff',
                        boxShadow: '-3px 0 20px rgba(0,0,0,0.1)',
                        padding: '30px',
                        overflowY: 'auto',
                        display: OrgEdit ? 'block' : 'none'
                    }}>
                        <OrgForm orgId={OrgEdit} />
                    </div>
                </CardContent>
                </>
                )}
                {activeTab === 'Decision Makers' && (
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Company</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>LinkedIn</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Remarks</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {decisionMakers.map((decisionMaker) => {
                                        const company = Project.TargetCompany.find(tc => tc.Organization === decisionMaker.TargetCompanyId);
                                        return (
                                           <>
                                            <TableRow key={decisionMaker._id}>
                                                <TableCell> <CompanyLogo companyName={company?.Name||""} sx={{ width: '60px', height: '60px' }} /> {company ? company.Name : 'Unknown'}</TableCell>
                                                <TableCell>{decisionMaker.candidateId.name}</TableCell>
                                                <TableCell>
                                                    <Link href={decisionMaker.candidateId.linkedin} target="_blank" rel="noopener">
                                                        LinkedIn
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                {  decisionMaker.status}<br/>
                                                <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                {
                                                    decisionMaker.status ==='Pending for Approval' && (
                                                        <Button
                                                            key="Approved for Outreach"
                                                            variant={decisionMaker.status === "Approved for Outreach" ? 'contained' : 'outlined'}
                                                            size="small"
                                                            onClick={() => handleConnectionStatusChange(decisionMaker.candidateId._id,"Approved for Outreach")}
                                                            sx={{
                                                                borderRadius: '2px',
                                                                textTransform: 'none',
                                                                minWidth: '60px'
                                                            }}
                                                        >
                                                       Approved for Outreach
                                                        </Button>
                                                    )
                                                }
                                                {

                                    ( (decisionMaker.status ==='Pending for Approval') &&  ['Hold','Drop'].map(status => (
                                                        <Button
                                                            key={status}
                                                            variant={decisionMaker.status === status ? 'contained' : 'outlined'}
                                                            size="small"
                                                            onClick={() => StatusChangeWithComment("", status,"",decisionMaker.candidateId._id)}
                                                            sx={{
                                                                borderRadius: '2px',
                                                                textTransform: 'none',
                                                                minWidth: '60px'
                                                            }}
                                                        >
                                                            {status}
                                                        </Button>
                                                    )
                                                    ))
                                                }
                                            {
                                                    decisionMaker.status ==='Hold' && (
                                                      ['Approved for Outreach','Drop'].map(status => (
                                                        <Button
                                                            key={status}
                                                            variant={decisionMaker.status === status ? 'contained' : 'outlined'}
                                                            size="small"
                                                            onClick={() => StatusChangeWithComment("",status,"",decisionMaker.candidateId._id)}
                                                            sx={{
                                                                borderRadius: '2px',
                                                                textTransform: 'none',
                                                                minWidth: '60px'
                                                            }}
                                                        >
                                                            {status}
                                                        </Button>
                                                    )
                                                    )
                                                    )}
                                                    {
                                                    decisionMaker.status ==='Drop' && (
                                                      ['Approved for Outreach','Hold'].map(status => (
                                                        <Button
                                                            key={status}
                                                            variant={decisionMaker.status === status ? 'contained' : 'outlined'}
                                                            size="small"
                                                            onClick={() => handleConnectionStatusChange(decisionMaker.candidateId._id, status)}
                                                            sx={{
                                                                borderRadius: '2px',
                                                                textTransform: 'none',
                                                                minWidth: '60px'
                                                            }}
                                                        >
                                                            {status}
                                                        </Button>
                                                    )
                                                    )
                                                    )}

                                                    
                                                </div>
                                                </TableCell>
                                                <TableCell>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => setShowComments(decisionMaker._id)}
                                            sx={{
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                minWidth: '120px',
                                                marginLeft: 'auto'
                                            }}
                                            startIcon={<EditIcon />}
                                        >
                                           {decisionMaker?.comments?.length || '' }  Comments
                                        </Button></TableCell>
                                        </TableRow>
                                        <TableRow key={`{decisionMaker._id}-1`}>
                                        <TableCell colSpan={7}>
                                        <div style={{ marginTop: '16px' }}>                                        
                                        {showComments === decisionMaker._id && (
                                            <div style={{ marginTop: '16px' }}>
                                                {decisionMaker.comments.map((comment, index) => (
                                                    <Card key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <b>{comment.userName}</b> * {new Date(comment.timestamp).toLocaleString()}
                                                        </Typography>
                                                        <Typography variant="body1">{comment.comment}</Typography>
                                                    </Card>
                                                ))}
                                                <TextField
                                                    fullWidth
                                                    label="Add a comment"
                                                    multiline
                                                    rows={4}
                                                    value={newComment?.comment || ''}
                                                    onChange={(e) => 
                                                    setNewComment({
                                                        comment: e.target.value,
                                                        user: user._id,
                                                        userName: user.name,
                                                        timestamp: new Date()
                                                    })}
                                                    sx={{ mt: 2 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleAddCommentdecisionMaker(decisionMaker._id)}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Add Comment
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    </TableCell>
                                        </TableRow>
                                        
                                        </>

                                           
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                )}
                {activeTab === 'Reachouts' && ( <Reachouts decisionMakers={decisionMakers} Project={Project} fetchDecisionMakers={fetchDecisionMakers}/>   
                )}
            </>    //*/
    ) //*/
    }
    { (!Project.Client) && ( <Signup />)}   
        <Modal open={openSetupGmail}  footer={null} onClose={()=>setOpenSetupGmail(false)}   >
            <SetupGmail />
        </Modal>
                <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Change Status to {newStatus}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Reason for Change"
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                                setNewComment({
                                    comment: newStatus + " : " + e.target.value,
                                    user: user._id,
                                    userName: user.name,
                                    timestamp: new Date()
                                });
                            }}                          
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                        <Button onClick={handleSubmit} color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>
        </Container>
        
        </>
    );
    
};

export default Execedge;