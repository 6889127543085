import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {  
  TextField,
  Button,
  Box,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  InputAdornment,
} from '@mui/material';

const OrgForm = ({ orgId }) => {
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  const [uploadedFileName, setUploadedFileName] = useState('');

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFileName(file.name);
      const formData = new FormData();
      formData.append('logo', file);
      try {
        const response = await axios.post('/api/upload/logo', formData);
        console.log('Logo uploaded successfully', response.data);
        const filePath = response.data.filePath;
        setOrg({ ...org, logo: filePath });
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  const fetchOrgData = async () => {
    try {
      const response = await axios.get(`/api/get/organizations/${orgId}`);
      setOrg(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching org:', error);
      setAlert({
        open: true,
        message: 'Error loading organization data',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orgId) fetchOrgData();
    else setLoading(false);
  }, [orgId]);

  const handleSave = async () => {
    try {
      if (orgId) {
        await axios.put(`/api/organizations/${orgId}`, org);
      } else {
        await axios.post('/api/organizations', org);
      }
      setAlert({
        open: true,
        message: 'Organization saved successfully!',
        severity: 'success'
      });
    } catch (error) {
      setAlert({
        open: true,
        message: 'Error saving organization',
        severity: 'error'
      });
    }
  };

  const handleFieldChange = (field, value) => {
    setOrg({ ...org, [field]: value });
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" marginTop={4}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box padding={4} maxWidth="960px" margin="auto">
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Organization Name"
            fullWidth
            margin="normal"
            value={org?.name || ''}
            onChange={(e) => handleFieldChange('name', e.target.value)}
          />
          <TextField
            label="Website"
            fullWidth
            margin="normal"
            value={org?.website || ''}
            onChange={(e) => handleFieldChange('website', e.target.value)}
          />
          <TextField
            label="Job Board URL"
            fullWidth
            margin="normal"
            value={org?.jobBoard || ''}
            onChange={(e) => handleFieldChange('jobBoard', e.target.value)}
          />
          <TextField
            label="LinkedIn URL"
            fullWidth
            margin="normal"
            value={org?.linkedin || ''}
            onChange={(e) => handleFieldChange('linkedin', e.target.value)}
          />
          <TextField
            label="Location"
            fullWidth
            margin="normal"
            value={org?.location || ''}
            onChange={(e) => handleFieldChange('location', e.target.value)}
          />
          <TextField
            label="Headquarters"
            fullWidth
            margin="normal"
            value={org?.headquarters || ''}
            onChange={(e) => handleFieldChange('headquarters', e.target.value)}
          />
          <TextField
            label="Number of Employees"
            fullWidth
            margin="normal"
            value={org?.employees || ''}
            onChange={(e) => handleFieldChange('employees', e.target.value)}
          />
          <TextField
            label="Industry"
            fullWidth
            margin="normal"
            value={org?.industry || ''}
            onChange={(e) => handleFieldChange('industry', e.target.value)}
          />
          <TextField
            label="Type"
            fullWidth
            margin="normal"
            value={org?.type || ''}
            onChange={(e) => handleFieldChange('type', e.target.value)}
          />
          <TextField
            label="Alias Names (comma separated)"
            fullWidth
            margin="normal"
            value={org?.alias?.join(', ') || ''}
            onChange={(e) => handleFieldChange('alias', e.target.value.split(',').map(s => s.trim()))}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              component="label"
            >
              Upload Logo
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
            {org?.logo && (
              <Button
                variant="outlined"
                href={org.logo}
                target="_blank"
                sx={{ ml: 2 }}
              >
                View Logo
              </Button>
            )}
          </Box>
          <TextArea
            aria-label="Description"
            minrows={3}
            placeholder="Organization Description"
            value={org?.description || ''}
            onChange={(e) => handleFieldChange('description', e.target.value)}
            style={{ width: '100%', marginTop: 10 }}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSave}
        sx={{ mt: 3 }}
      >
        {orgId ? 'Update Organization' : 'Add Organization'}
      </Button>
    </Box>
  );
};

export default OrgForm;