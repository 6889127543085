import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = 'https://api.hireveda.com/api/'; // Replace with your API base URL

const GoogleCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
 
  let run = false;

  useEffect(() => {  
  const handleAuthorization = async () => {
    if (run) return; // Prevent multiple clicks
    run = true;
    if(code === null) return;
  
    try {
      const state = JSON.parse(decodeURIComponent(urlParams.get('state')));
      const user = state.id;
     // console.log('state:', state);
      const response = await fetch('/api/exchange-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, user}),
      });
  
      const data = await response.json();
      console.log('Tokens:', data);
      window.location.href = state.url; // Redirect to the homepage
    } catch (error) {
      console.error('Error exchanging authorization code:', error);
    } 
  };
  handleAuthorization();
  
    }, [code]);
  

  return (
    <div>
    </div>
  );
};




export default GoogleCallback;